<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog" element-loading-text="等待腾讯直播响应中..." v-loading="tableloading">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="选择商品">
						<span class="xx">*</span>
						<el-select multiple filterable clearable v-model="ruleForm.product_id" placeholder="请选择商品(可搜索)"
							@change="change">
							<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/importGood";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					product_id: [],
					room_id: ''
				},
				list: [],
				listId: [],
				tableloading: false
			}
		},
		methods: {
			// 选中
			change(e) {
				this.listId = e
			},
			Reset() {
				this.ruleForm.product_id = []
			},
			// 获取商品
			getList() {
				this.$http.post(api.goodlist, {
						page: 1,
						limit: 99999,
						audit_status:2
					})
					.then((res) => {
						if (res.code == 200) {
							this.list = res.data.list
						}
					})
			},
			//新增/编辑
			SubmitForm() {
				let that = this
				if (this.ruleForm.product_id.length==0) {
					ElMessage({
						message: '请选择商品',
						type: 'warning',
					})
				} else {
					this.$http.post(api.add, this.ruleForm)
						.then((res) => {
							this.tableloading = true
							if (res.code == 200) {
								this.tableloading = false
								ElMessage({
									type: 'success',
									message: '添加成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						}).catch(err => {
							that.tableloading = false
						})
				}
			},

			// 显示隐藏
			show(val, id, room_id) {
				this.title = val
				this.ruleForm.room_id = room_id
				this.getList()
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>
